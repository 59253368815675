import { ShipGoodsInvalidType } from '@/public/src/pages/checkout_new/utils/constant'
import { setShippingMethod } from './dataMapping/shippingMethod'

export default {
  // 默认选中的运输方式
  selectShippingMethod({ shippingMethodState }, { mallCode, transportType }) {
    const currMall = shippingMethodState.selectedShippingList.find(item => +item.mall_code === +mallCode)
    
    if (!currMall) {
      return false
    }
    
    if(currMall.transport_type === transportType) {
      console.warn('[shippingMethod] not change')
      return
    }
    
    // 上一次运输方式
    const lastTransType = currMall.transport_type
    
    // 设置选中
    shippingMethodState.selectedShippingList = shippingMethodState.selectedShippingList.map(item => {
      if (+item.mall_code === +mallCode) {
        return {
          ...item,
          transport_type: transportType,
          last_transport_type: lastTransType,
          being_check: 1
        }
      }
      
      return {
        ...item,
        being_check: 0
      }
    })
  },
  initShippingSelectedMethod(state, { checkout }) {
    setShippingMethod(state, {
      checkout
    })
  },
  recoverLastShippingMethod({ shippingMethodState }, { shippingMethodList }) {
    // 设置选中
    shippingMethodState.selectedShippingList = shippingMethodList
  },
  updateAddressRelativeError({ shippingMethodState }, addressRelativeError) {
    shippingMethodState.addressRelativeError = addressRelativeError
  },
  resetShippingLimits({ shippingMethodState }) {
    shippingMethodState.popup.goodsLimit = {
      invalidType: ShipGoodsInvalidType.NO_INVALID,
      limitInfo: {
        popupTip: '',
        title: '',
        suggestAddress: {},
        changeAddrText: '',
        changeTransportText: '',
        suggestTransportType: '',
        nextCheckedTransportType: '',
        mallCode: '',
        match_carts: [],
        suggest_shipping_method: {}
      },
      no: 0
    }
  },
  updateLimitNum({ shippingMethodState }, val) {
    shippingMethodState.popup.goodsLimit.no = val
  },
  updateShipPopupState({ shippingMethodState }, newVal) {
    // 临时处理，待优化
    if(newVal.goodsLimit.no == null) {
      newVal.goodsLimit.no = shippingMethodState.popup.goodsLimit.no || 0
    }
    shippingMethodState.popup = {
      ...shippingMethodState.popup,
      ...newVal
    }
  },
  updateShippingState({ shippingMethodState }, newVal) {
    Object.keys(newVal).forEach((key) => {
      shippingMethodState[key] = newVal[key]
    })
  },
  updateCurrentFreightFreePopup({ shippingMethodState }, { selectedInfo }) {
    const { mall_code } = shippingMethodState.selectedShippingList[0]
    
    shippingMethodState.popup = {
      ...shippingMethodState.popup,
      shippingFee: {
        visible: true,
        mallCode: mall_code,
        transportType: selectedInfo[mall_code].transport_type,
      }
    }
  },
  updateShipPopupByKey({ shippingMethodState }, payload) {
    const popupState = shippingMethodState.popup
    const { key, value } = payload
    const groups = key.split('.')
    const last = groups[groups.length - 1]
    let targetState = popupState
    
    for(let i = 0; i < groups.length - 1; i++) {
      targetState = popupState[groups[i]] || {}
    }
    
    if(targetState[last] && typeof targetState[last]  === 'object') {
      targetState[last]  = {
        ...targetState[last],
        ...value
      }
    } else {
      targetState[last] = value
    }
  },
}
