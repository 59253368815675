import useCoupon from '@/public/src/pages/checkout_new/hooks/useCoupon.js'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { Toast as $toast } from '@shein/sui-mobile'

export default {
  // 处理checkout各种优惠限制场景报错（积分、券、钱包、礼品卡、支付方式）
  handleRestrictionResp({ commit, state, getters }, { code, res, params, scene }) {
    if (+code === 300511 && params?.payment_code === 'cod') {
      // 用券触发、用积分触发
      let codConfirmTxt =
        scene === 'coupon'
          ? state.language.SHEIN_KEY_PWA_16090
          : state.language.SHEIN_KEY_PWA_16091
      const resInfo = res?.info?.notSupportCodTips?.params || {}
      codConfirmTxt = codConfirmTxt
        .replace('{min}', resInfo.min)
        .replace('{max}', resInfo.max)

      commit('updateCommonDialogState', {
        codConfirmDialog: {
          show: true,
          tip: codConfirmTxt,
          coupon: scene === 'coupon' ? params.coupon_list : [],
          points: scene === 'point' ? params.points : '',
          scene
        }
      })

      // 待支付确认，待修改 修改人@Xin Liu 待确认该场景是否还需要
      // commit('assignState', { codDisplay: 1, loading: false })

      return
    }

    // 获取当前使用的卡 bin 优惠券信息
    const getNowUseBinCouponInfo = ({ couponsRes, params }) => {
      const nowParamsCouponList = (params?.coupon_list || []).map(item => item?.toLocaleUpperCase())
      const { getAllListCoupons } = useCoupon()

      // 目前使用的卡 bin 优惠券的信息
      const nowUseCouponInfo = getAllListCoupons(couponsRes).filter(item =>
        nowParamsCouponList?.includes(item?.coupon?.toLocaleUpperCase()) // 目前使用的券
          && item?.businessExtension?.order?.card?.cardCodes?.length > 0 //卡bin优惠券
      ) || []

      console.log('nowUseCouponInfo >>>>>>', nowUseCouponInfo)
      return {
        nowUseCouponInfo
      }
    }

    // 卡 bin 优惠券可以支持的支付方式
    const getBinCouponsPayments = () => {
      return getters.paymentInfo?.payments?.filter(item =>
        ['routepay-card', 'routepay-cardinstallment'].includes(item.code) && item.enabled == 1) || []
    }

    // 目前使用的卡 bin 优惠券是否有可用的卡 bin
    const getIsHadCanUseBin = ({ nowUseCouponInfo, binCouponsPayments }) => {
      const nowCardCodesList = nowUseCouponInfo?.reduce((acc, item) => {
        return acc.concat(item?.businessExtension?.order?.card?.cardCodes || [])
      }, [])
      const nowAllCardBinList = binCouponsPayments?.reduce((acc, item) => {
        let nowAddTokenList = item?.card_token_list?.map(i => i?.card_bin) || []
        // 卡分期 token abt 不支持
        if (item?.code === 'routepay-cardinstallment') {
          const isShowTokenFrontInstallmentAbt = state?.abtInfo?.showTokenFrontInstallment?.param?.showTokenFrontInstallmentStatus !== '0'  // 展示卡分期token前置功能
          if (!isShowTokenFrontInstallmentAbt) {
            nowAddTokenList = []
          }
        }
        return acc.concat(nowAddTokenList)
      }, [])
      
      return nowCardCodesList.some(item => nowAllCardBinList.includes(item))
    }

    const isHadToken = (binCouponsPayments) => {
      return binCouponsPayments?.some(item => {
        if (item?.code === 'routepay-cardinstallment') {
          const isShowTokenFrontInstallmentAbt = state?.abtInfo?.showTokenFrontInstallment?.param?.showTokenFrontInstallmentStatus !== '0'  // 展示卡分期token前置功能
          if (item?.card_token_list && isShowTokenFrontInstallmentAbt) {
            return true
          }
        }

        return item?.card_token_list
      })
    }

    const preUseCouponList =
      state.checkout?.coupon_list?.map(item =>
        item?.couponCode?.toLocaleUpperCase()
      ) || []

    // 是否之前已经成功用券
    const isPreUseSuccessCoupon = [...(params?.coupon_list || []), params?.add_coupon]
      .filter(item => item?.length > 0)
      .every(item => preUseCouponList.includes(item?.toLocaleUpperCase()))

    const isShowPrePayModal = state?.paymentState?.payInstanceEvent?.getIsShowPrePayModal?.()

    // 卡支付前置错误逻辑处理
    if ([302458, 300627].includes(code) && isShowPrePayModal) {
      state?.paymentState?.payInstanceEvent?.handlerPrePayCouponDrawer(true)
      return
    }
    // 前面使用的券成功，且当前接口返回的code是302458或300627
    if (isPreUseSuccessCoupon && [302458, 300627].includes(code)) {
      commit('updateCommonDialogState', {
        confirmPaymentCoupon: {
          show: true,
          isSelectCouponsBinError: state.checkoutParams?.payment_code?.length > 0 && code === 302458,
          lastCheckoutParams: state.checkoutParams,
          errType: code,
          params
        }
      })
      return
    }

    // 卡bin优惠券逻辑
    // 302458: 入参支付方式和卡bin优惠优惠券限制的支付方式一致，但是卡bin不一致
    if (code === 302458) {
      const { useGetCouponList, useGetIsHadCoupon } = useCoupon()
      const isHadCoupon = useGetIsHadCoupon(params?.coupon_list)
      const handleCouponErr = (couponsRes) => {
        // 卡 bin 优惠券可以支持的支付方式
        const binCouponsPayments = getBinCouponsPayments()
  
        // 目前使用的卡 bin 优惠券的信息
        const { nowUseCouponInfo } = getNowUseBinCouponInfo({
          couponsRes,
          params
        })

        const isShowBinCouponsDrawer =
          binCouponsPayments?.length > 0 && nowUseCouponInfo?.length > 0

        if (isShowBinCouponsDrawer) {
          // 是否有可用的卡 bin
          const isHadCanUseBin = getIsHadCanUseBin({
            nowUseCouponInfo,
            binCouponsPayments
          })

          if (isHadCanUseBin) {
            commit('updateCommonDialogState', {
              binCouponsDrawer: {
                show: true,
                nowUseCouponInfo,
                errType: 302458,
                paymentCoupon: []
              }
            })
          } else {
            $toast(res?.msg || '')
          }
        }
      }
      if (!isHadCoupon) {
        useGetCouponList({
          handleType: 'newApplyCoupin',
          payCb: couponsRes => {
            handleCouponErr(couponsRes)
          }
        })
      } else {
        handleCouponErr(state.couponListState)
      }
      return
    }
    // 300627: 入参支付方式和卡bin优惠优惠券限制的支付方式无交集
    if (code === 300627) {
      // 取 可用支付方式 和 支付优惠券限定的支付方式 交集
      let arr = []
      if (res?.info?.payment_coupon?.length) {
        arr = getters.paymentInfo?.payments?.filter(item => {
          if (item.enabled && res.info.payment_coupon.includes(item.code)) {
            return item
          }
        })
      }
      console.log('state.couponListState >>>>', state.couponListState)

      const { useGetCouponList, useGetIsHadCoupon } = useCoupon()
      const isHadCoupon = useGetIsHadCoupon(params?.coupon_list)
      const handleCouponErr = (couponsRes) => {
        // 卡 bin 优惠券可以支持的支付方式
        const binCouponsPayments = getBinCouponsPayments()

        // 目前使用的卡 bin 优惠券的信息
        const { nowUseCouponInfo } = getNowUseBinCouponInfo({
          couponsRes,
          params
        })

        const isHadCanUseBin = getIsHadCanUseBin({
          nowUseCouponInfo,
          binCouponsPayments
        })

        const isPayHadToken = isHadToken(binCouponsPayments)
        const isShowBinCouponsDrawer = (isHadCanUseBin && isPayHadToken) && nowUseCouponInfo?.length > 0

        if (isShowBinCouponsDrawer) {
          commit('updateCommonDialogState', {
            binCouponsDrawer: {
              show: true,
              nowUseCouponInfo,
              errType: 300627,
              paymentCoupon: res?.info?.payment_coupon
            }
          })
        } else {
          if (isPayHadToken) {
            commit('updateCommonDialogState', {
              paymentCouponDrawer: {
                show: true,
                lastCheckoutParams: params, // 最后一次checkout的请求参数
                paymentCouponList: arr
              }
            })

            commit('updateShipPopupByKey', {
              key: 'couponDrawerVisible',
              value: false
            })
          } else {
            $toast(res?.msg || '')
          }

          daEventCenter.triggerNotice({
            id: `expose_popup_couponselectpaymethodbox:simple`
          })
        }
      }
      if (!isHadCoupon) {
        useGetCouponList({
          handleType: 'newApplyCoupin',
          payCb: couponsRes => {
            handleCouponErr(couponsRes)
          }
        })
      } else {
        handleCouponErr(state.couponListState)
      }
      return
    }
  }
}
