// 客户端环境
export const isClient = typeof window !== 'undefined'

// 公共类名前缀
export const commonPrefixCls = (prefix, cls) => {
  prefix = prefix || ''
  return `${prefix}${cls}`
}

/*
 * 传入秒级
 * startTime 开始时间
 * endTime 结束时间
 * onCompleted 倒计时结束回调
 * cb 倒计时回调 输出 时分秒
 */
export function useCountDown(
  options = { startTime: '', endTime: '', interval: 1000, onCompleted: null },
  cb = () => {}
) {
  let { startTime, endTime, interval = 1000, onCompleted = () => {} } = options
  if (!+startTime || !+endTime || endTime - startTime < 1) {
    cb({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      seconds_transform: '00',
      days_transform: '00',
      hours_transform: '00',
      minutes_transform: '00',
      expired: false
    })
    if (typeof onCompleted === 'function') onCompleted()
    return
  }
  let timer = null
  let countDownTime = (endTime - startTime) * 1000

  const parseMs = milliseconds => {
    const timeObje = {
      days: Math.floor(milliseconds / 86400000),
      hours: Math.floor(milliseconds / 3600000) % 24,
      minutes: Math.floor(milliseconds / 60000) % 60,
      seconds: Math.floor(milliseconds / 1000) % 60
    }
    const results = {}
    Object.entries(timeObje).forEach(item => {
      if (item[1] < 10) {
        results[`${item[0]}_transform`] = `0${item[1]}`
      } else {
        results[`${item[0]}_transform`] = String(item[1])
      }
    })

    return {
      ...timeObje,
      ...results,
      expired:
        !timeObje.days &&
        !timeObje.hours &&
        !timeObje.minutes &&
        !timeObje.seconds
    }
  }

  const clear = () => {
    countDownTime = 0
    clearInterval(timer)
  }

  if (countDownTime > 0) {
    cb({
      ...parseMs(countDownTime),
      clear
    })

    !timer &&
      (timer = setInterval(() => {
        countDownTime -= interval
        if (countDownTime <= 0) {
          clear()
          if (typeof onCompleted === 'function') onCompleted()
        }
        cb({
          ...parseMs(countDownTime),
          clear
        })
      }, interval))
  }
}

/**
 * @description 判断两个值是否相等【仅仅针对基本类型】
 * @remark isEqual(1,'1') === true 这里处理 1 === '1'[主要处理后端value类型不一致的问题]
 * @returns{true, false}
 * */
export const isEqual = (value1, value2) => {
  function isNumberOrString(value) {
    return typeof value === 'number' || typeof value === 'string'
  }

  if (!isNumberOrString(value1) || !isNumberOrString(value2)) return false
  if (typeof value1 !== typeof value2) {
    return String(value1) === String(value2)
  }
  return value1 === value2
}

//canvas计算一段文本的长度【高性能】
export const getActualWidthOfChars = (text, options = {}) => {
  const { size = 8, family = 'Helvetica,Arial,sans-serif' } = options
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  ctx.font = `${size}px ${family}`
  const metrics = ctx.measureText(text)
  const actual =
    Math.abs(metrics.actualBoundingBoxLeft) +
    Math.abs(metrics.actualBoundingBoxRight)
  return Math.max(metrics.width, actual)
}

export const leftJoin = (arr1, arr2, equalCondition) => {
  return arr1.map(item1 => {
    const item2 = arr2.find(item2 => equalCondition(item1, item2))
    return { ...item1, ...item2 }
  })
}

// 两数相加
export const sum = (array, key) => {
  return array.reduce((accumulator, item) => {
    return accumulator + (key ? parseFloat(item[key]) : item)
  }, 0)
}

/**
 * @description 文本多行则换行，换行则字号变小
 * @param {text} String 渲染文本
 * @param {fontSize:{ default: 10, wrap: 8 }} Object default 默认字号；wrap换行字号
 * @param {renderWidth} Number 渲染文本区域的宽度
 * @param {renderWidthUnit} 'rem' | 'px' 渲染文本区域的宽度单位，默认rem
 * @param {tag} String 默认div，可以自定义
 * @param {lineClamp} Number 行数
 * @param {options} CSSProperties css样式，比如字号，字体等等
 * */

export const textWrapAndScaling = ({
  text = '',
  renderWidth = window?.innerWidth || 375,
  renderWidthUnit = 'rem',
  fontSize = { default: 10, wrap: 8 },
  tag = 'div',
  lineClamp = 2,
  options = { family: '' } // CSSProperties
}) => {
  if (!isClient) {
    throw new Error('请确保isClient为true')
  }

  const divTag = document.createElement(tag)
  // 字号
  let _fontSize = fontSize.default
  const textWidth = getActualWidthOfChars(text, {
    size: fontSize.default,
    ...options
  })

  const realRenderWidth =
    renderWidthUnit === 'rem' ? remToPx(renderWidth) : renderWidth
  if (textWidth > realRenderWidth) {
    // 超出缩小字号
    _fontSize = fontSize.wrap

    // 超出一行，控制line，溢出用...替代
    divTag.style.overflow = 'hidden'
    divTag.style.textOverflow = 'ellipsis'
    divTag.style.wordWrap = 'break-word'
    divTag.style.display = '-webkit-box'
    divTag.style['-webkit-line-clamp'] = lineClamp
    divTag.style['-webkit-box-orient'] = 'vertical'
  }
  divTag.style.fontSize = `${_fontSize}px`
  divTag.innerHTML = text

  // 使用 XMLSerializer 序列化 DOM
  const serializer = new XMLSerializer()
  const elementString = serializer.serializeToString(divTag)
  // 删除xmlns属性
  return elementString.replace(/\sxmlns="[^"]+"/, '')
}

/**
 * @description 文本多行则换行，换行则字号变小[富文本渲染]
 * @param {text} String 渲染文本
 * @param {fontSize:{ default: 10, wrap: 8 }} Object default 默认字号；wrap换行字号
 * @param {renderWidth} Number 渲染文本区域的宽度
 * @param {tag} String 默认div，可以自定义
 * @param {lineClamp} Number 行数
 * @param {options} CSSProperties css样式，比如间距，字体等等
 * @param {renderWidthUnit} 'rem' | 'px' 渲染文本区域的宽度单位，默认rem
 * */
export const textWrapAndScalingByRTF = ({
  text = '',
  renderWidth = window?.innerWidth || 375,
  renderWidthUnit = 'rem',
  fontSize = { default: 10, wrap: 8 },
  tag = 'div',
  lineClamp = 2,
  options = { family: '' }, // CSSProperties
  renderDom = document.body
}) => {
  if (!isClient) {
    throw new Error('请确保isClient为true')
  }

  const divTag = document.createElement(tag)
  divTag.style.cssText = `
    position: absolute;
    left: -999999rem;
    top: -999999rem;
    z-index: -9;
    opacity:0;
    white-space: nowrap;
    font-size:${fontSize?.default}px;
    font-family: ${options?.family || 'Helvetica,Arial,sans-serif'};
  `
  divTag.innerHTML = text
  renderDom.appendChild(divTag)
  const textWidth = divTag.clientWidth

  const renderDivTag = document.createElement(tag)
  renderDivTag.innerHTML = text

  const realRenderWidth =
    renderWidthUnit === 'rem' ? remToPx(renderWidth) : renderWidth
  if (textWidth > realRenderWidth) {
    // 超出缩小字号
    renderDivTag.style.fontSize = `${fontSize.wrap}px`
    // 超出一行，控制line，溢出用...替代
    renderDivTag.style.overflow = 'hidden'
    renderDivTag.style.textOverflow = 'ellipsis'
    renderDivTag.style.wordWrap = 'break-word'
    renderDivTag.style.display = '-webkit-box'
    renderDivTag.style['white-space'] = 'normal'
    renderDivTag.style['-webkit-line-clamp'] = lineClamp
    renderDivTag.style['-webkit-box-orient'] = 'vertical'
  }
  renderDom.removeChild(divTag)

  // 使用 XMLSerializer 序列化 DOM
  const serializer = new XMLSerializer()
  const elementString = serializer.serializeToString(renderDivTag)
  // 删除xmlns属性
  return elementString.replace(/\sxmlns="[^"]+"/, '')
}

/**
 * @description rem转px
 * @param {rem} Number 设计稿尺寸
 * @return {px} Number 转换成的尺寸，不携带单位
 * */

export const remToPx = rem => {
  let documentRootFontSize = ''
  try {
    // 比如 根节点html标签的style="font-size: 37.5px;" ； 那么 1rem = 37.5px
    documentRootFontSize = window
      ?.getComputedStyle(document?.documentElement)
      ?.fontSize?.replace('px', '') || ''
    if (typeof rem !== 'number') {
      throw new Error('rem参数为number类型')
    }
    if (!documentRootFontSize) {
      throw new Error('获取根节点html标签的字号错误，请确保isClient为true')
    }
  } catch (error) {
    console.error(error)
  } finally {
    return rem * parseFloat(documentRootFontSize)
  }
}

/**
 * 判断购物车中是否存在某促销活动
 * @param {Array} carts 购物车商品
 * @param {Array} activityTypeIds 促销活动typeId
 * @return activity_method 0存在 1不存在
 */
export const judgeActivityIsExist = (carts = [], activityTypeIds = []) => {
  let activity_method = 1
  carts.forEach(item => {
    item?.product?.product_promotion_info?.forEach(info => {
      if (activityTypeIds.includes(+info.type_id)) {
        activity_method = 0
      }
    })
  })
  return activity_method
}

/**
 * 遍历支付方式树，查找符合条件的支付方式
 *
 * @param {Trade_PayToolKit.PaymentInfoItem[]} data
 * @param {(v: Trade_PayToolKit.PaymentInfoItem) => boolean} predicate
 * @return {*}  {Trade_PayToolKit.SelectedPaymentInfo}
 */
export function findPaymentItem(data, predicate) {
  const queue = [...data] // 使用队列来处理广度优先搜索
  while (queue.length > 0) {
    const node = queue.shift() // 获取并移除队列的第一个元素
    if (node && predicate(node)) return node // 找到匹配的节点，返回该节点
    // 将子节点追加到队列
    if (node?.payments && node?.payments?.length > 0) {
      queue.push(...node.payments)
    }
  }

  return null // 没有找到匹配的节点，返回 null
}

/**
 * 深度遍历支付方式树
 * 
 * @param {Trade_PayToolKit.PaymentInfoItem[]} payments 
 * @param {(() => boolean) | (() => void)} handleIteratePayment 回调处理函数
 */
export function iteratePayments(payments, handleIteratePayment = () => {}) {
  if (!Array.isArray(payments)) return
  let shouldBreak = false
  for (const payment of payments) {
    const { payments } = payment || {}
    if (payments?.length) {
      iteratePayments(payments)
    } else {
      shouldBreak = handleIteratePayment?.(payment) || false
    }
    if (shouldBreak) break
  }
}

/**
 * 替换支付成功页的referer
 *
 * @export
 */
export function replacePaySuccessReferer () {
  const { pathname = '' } = location || {}
  const orderListPath = `${gbCommonInfo?.langPath}/user/orders/list`
  if (pathname !== orderListPath) {
    history.replaceState(history.state ?? {}, null, orderListPath)
  }
}

export function debuggerLog(...args) {
  if (typeof window === 'undefined') return
  try {
    const enableDebugLog = sessionStorage?.getItem?.('__bs__enable_debug_log')
    if (!enableDebugLog) return
    console.info('%cCheckout Debug:', 'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff', ...args, '\n')
  } catch (e) {
    console.log(...args)
  }
}