import { CardType } from 'public/src/pages/checkout_new/pages/prime_saver/utils/constant.js'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

export default {
  selectPrime({ checkout, primeSaverState }, code) {
    const index = checkout.prime_info?.prime_products?.findIndex(item => item.product_code === code)
    Object.assign(primeSaverState, { selectedPrimeIndex: index })
  },
  selectSaver({ primeSaverState }, code) {
    Object.assign(primeSaverState, { selectedSaverCode: code })
  },
  updatePrimeSaverState({ primeSaverState }, payload) {
    Object.assign(primeSaverState, payload)
  },
  switchToNormalCard({ checkout, primeSaverState }, paymentInfo) {
    const switchCardList = []
    const { popup_type, is_recovery } = checkout?.auto_renewal?.switch_payment_popup || {}
    const { support_save_card_quick, support_prime_quick } = paymentInfo?.origin_payment_info || {}

    // 当 switch_payment_popup 不为空时，代表选中续费产品包
    const isSaverAutoRenew = [CardType.SAVE, CardType.BOTH].includes(popup_type)
    const isPrimeAutoRenew = [CardType.PRIME, CardType.BOTH].includes(popup_type)
    
    if (isSaverAutoRenew && +support_save_card_quick === 0) {
      switchCardList.push(CardType.SAVE)
    }
    if (isPrimeAutoRenew && +support_prime_quick === 0) {
      switchCardList.push(CardType.PRIME)
    }

    if (switchCardList.length > 0) {
      // 打开切包弹窗曝光
      daEventCenter.triggerNotice({
        id: 'expose_autorenew_switch:simple',
        data: {
          autorenew_switch_scene: is_recovery ? 1 : 0,
          renew_type: popup_type == CardType.PRIME ? '2' : '1'
        }
      })
      Object.assign(primeSaverState, {
        switchCardDrawer: {
          show: true,
          switchCardList,
          selectPaymentInfo: {
            ...paymentInfo,
            ...paymentInfo?.origin_payment_info
          }
        }
      })
    }
  },
}
