import { useFindPayment } from '../../hooks/useFindPayment'
import { useGetIsHadDefaultBankPayment } from '../../hooks/useGetIsHadDefaultBankPayment'

export default {
  // 支付方式类型 NormalBtnType from 'public/src/pages/checkout_new/pages/footer/utils/constant.js'
  paymentMethodType(state) {
    return state.checkout?.placeOrderButton?.type
  },

  // 处理 button 弹窗利诱点数据
  getActivityPopoverData: ({ checkout, cartsInfo }) => {
    let popoverLurePoints = []
    if (checkout?.placeOrderLurePoints) {
      popoverLurePoints.push(...checkout.placeOrderLurePoints)
    }
    if (cartsInfo?.placeOrderLurePoints) {
      popoverLurePoints.push(...cartsInfo.placeOrderLurePoints)
    }
    return popoverLurePoints
      .filter(i => Number(i.is_display) === 1)
      .sort((a, b) => a.priority - b.priority)
  },

  isShowBottomPaymentSelector: state => {
    const { checkout, abtState } = state
    const selectedPayment =
      useFindPayment(
        checkout.payment_info?.payments || [],
        v =>
          (v.last_payment && +v.enabled === 1) ||
          useGetIsHadDefaultBankPayment(v)
      ) || {}
    return (
      (selectedPayment && abtState?.isOrderpaylistbottomstatus) ||
      (!selectedPayment && abtState?.isOrderpaylistbottomnodefaultstatus)
    )
  }
}
