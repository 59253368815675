export default () => ({
  primeSaverState: {
    selectedPrimeIndex: -1,
    selectedSaverCode: '',
    // 付费会员详情弹窗
    primeDetailsDrawerVisible: false,
    // 付费会员详情弹窗触发的地方  1-随单购模块;2-物流运输方式;3-下单页挽留弹窗(已废弃)
    primeDetailsDrawerLocation: '',
    // 切包弹窗
    switchCardDrawer: {
      show: false,
      switchCardList: [],
      // 当前选择的支付方式
      selectPaymentInfo: {}
    },
  }
})
