export const QuickShipType = {
  /* 正常航运 */
  NORAML: 'NormalShipping',

  /* 全部快速航运 */
  ALL: 'AllQuickShipping',

  /* 部分快速航运 */
  PART: 'PartQuickShipping'
}

export const ShippingType = {
  /* 正常航运 */
  NORMAL: 'normal',

  /* 全部快速航运 */
  QUICKSHIP: 'quickShip'
}

export const QsFreightType = {
  true: '1',
  false: '2'
}

export const Operation = {
  DELETE: 'delete',
  UPDATE: 'update_quantity'
}

export const LargeShipKeys = ['1', '2']

export const ModeList = ['suggestV1', 'suggestV1Special', 'suggestV2']