import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { VirtualAssetsTypes } from '@/public/src/pages/checkout_new/utils/constant'

export default {
  async handleVirtualAssetsResp({ commit }, { code, info }) {
    if(code === 301714) {
      daEventCenter.triggerNotice({
        id: 'expose_popup_giftcarddevicetrytoomanylock:simple',
        data: {
          error_scene: 0
        }
      })
      
      commit('updateCommonDialogState', {
        commonErrDialog: {
          show: true,
          tip: info.tips,
          cb: () => {
            daEventCenter.triggerNotice({
              id: 'click_giftcarddevicetrytoomanylock_ok:simple',
              data: {
                error_scene: 0
              }
            })
          }
        }
      })
      
      return
    }
    
    if(code === 300366) {
      commit('updateVirtualAssetsPopupState', {
        freezeLimitGoods: {
          visible: true,
          type: VirtualAssetsTypes.GIFT_CARD,
        }
      })
      
      return
    }
  }
}
