export default () => ({
  // checkout接口的入参
  checkoutParams: {
    current_action: '', // 用户当前的行为动作
    is_old_version: '0',
    from_scene: '', // 从paypal探索页面进来则传「paypal_checkout」，否则不传
    isFirst: '0', // 首次进入传1，在下单页的后续操作调的接口传0
    scene: '', // 立即购传「buy_now」，否则不传

    // 支付相关
    temporaryCardBinList: '',
    cardPaymentFront: '',
    hasCardBin: '',
    usedCardBin: '',
    skip_calculate_with_payment: '',
    request_card_token: '',
    token_id: '',
    installment_token_id: '',
    payment_code: '',
    payment_code_unique: '',

    // 地址相关
    address_id: '', // 地址id
    country_id: '', // 	地址国家id
    state: '',
    city: '',
    postcode: '',
    shop_transit_country_id: '', // 店配所在国家

    shipping_methods: [],
    edit_as_same_shipping_method: '', // 是否确认改为同一种运输类型的标志(多mall 宅店切换场景，出完确认弹窗后点击确认切换需要传1)
    free_shipping_add_info: {}, // 运输方式免邮凑单信息

    // 优惠券相关
    coupon_list: [],
    add_coupon: '', // 手动券
    is_manual_used_coupon: '', // 用户是否手动选择了优惠券

    prime_product_code: '',
    save_card_product_code: '',
    cod_plan: '',
    lastDiscountType: '',
    card_no: '',
    card_pin: '',
    use_wallet: '',
    use_wallet_amount: '',
    points: '',
    subCurrencyCode: '',
    fromCartOrderBnpl: '',
    checkout_no: ''
  }
})
