import { useMapState, useMapMutations, useMapGetters } from '@/public/src/pages/checkout_new/hooks/store.js'
import { TRANSPORT_TYPE, OPERATE_TYPE } from './../utils/constant.js'
import { useDelayMount } from '@/public/src/pages/checkout_new/pages/address_bar/hooks/useDelayMount'


export default () => {
  /*------ useMap*** ------*/
  const { checkoutParams, checkout, initialSsrParamsInfo } = useMapState(['checkoutParams', 'checkout', 'initialSsrParamsInfo'])
  const { updateAddressBarState } = useMapMutations(['updateAddressBarState'])
  const { getDefaultAddress } = useMapGetters(['getDefaultAddress'])
  const { changeWriterToReady } = useDelayMount()
  
  /**
 * @description: 用于唤醒地址填写弹窗，其他vue文件就不需要导入各种useMap***等地址相关参数，同时，这里进行一些兜底判断
 * @param {Object} params ！！！！！！使用的时候要注意 operateType, addressType, addressData的传递！！！！！！
 * @return {*}
 */
  const handleAddressDrawer = (params) => {
    const { status, operateType, addressType, addressData = {}, extraData = {}, callBack = () => {} } = params

    const isNotAddOperateType = [OPERATE_TYPE.EDIT, OPERATE_TYPE.SUPPLEMENT, OPERATE_TYPE.DELETE].includes(operateType)
  
    if (status && (!operateType || !addressType)) {
      return
    }

    let newAddressData = addressData
    let otherData = extraData

    // 兜底增加店配的国家
    if (status && operateType === OPERATE_TYPE.ADD && addressType === TRANSPORT_TYPE.SHOP && !otherData?.countryId) {
      otherData = { ...extraData, countryId: addressData?.countryId || checkoutParams.value?.country_id || checkout.value?.no_address_calculate_address_info?.country_id || initialSsrParamsInfo.value?.country_id }
    }

    // 兜底在除添加地址外，无地址数据的时候，则增加地址数据，最好外部传入，数据源比较清晰
    if (status && isNotAddOperateType && !addressData) {
      newAddressData = getDefaultAddress.value || {}
    }
    
    changeWriterToReady()
    updateAddressBarState({
      addressWriteDrawer: {
        status,
        operateType,
        addressType,
        addressData: newAddressData,
        extraData: otherData,
        callBack: (params) => {
          callBack(params)
        },
      }
    })
  }

  return {
    handleAddressDrawer,
    OPERATE_TYPE,
    TRANSPORT_TYPE
  }
}
