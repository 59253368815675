import { ShipGoodsInvalidType } from '@/public/src/pages/checkout_new/utils/constant'

export default () => ({
  shippingMethodState: {
    // 选中列表
    selectedShippingList: [],
    
    /**
      提供给地址使用：
     当且仅当调用无地址添加【402502, 402501, 403370, 401906, 401973, 400322, 401972】时为false，其余运输方式错误为true
     */
    addressRelativeError: false,
    
    // 上次保存的地址
    lastAddress: null,
    
    // 运输方式点击历史记录
    histories: [],
    

    // 弹窗状态集合
    popup: {
      // 环境保护弹窗
      environVisible: false,

      // 运费险
      insurance: {
        visible: '',
        content: ''
      },

      // 限制类弹窗
      goodsLimit: {
        invalidType: ShipGoodsInvalidType.NO_INVALID,
        limitInfo: {
          popupTip: '',
          title: '',
          suggestAddress: {},
          changeAddrText: '',
          changeTransportText: '',
          suggestTransportType: '',
          nextCheckedTransportType: '',
          mallCode: '',
          match_carts: [],
          suggest_shipping_method: {}
        },
        no: 0
      },

      // 运输时效百分比
      percentage: {
        visible: false,
        mallCode: '',
        transportType: ''
      },


      quickShipPopup: {
        visible: false,
        label: '',
        content: ''
      },

      // 虚拟资产coupon列表抽屉
      couponDrawerVisible: false,

      // 大件海陆运弹窗
      largeShipInfo: {
        visible: false,
        seaLandInfo: {}
      },

      // 大件运费
      shippingFee: {
        visible: false,
        transportType: '',
        mallCode: ''
      }
    }
  }
})
