export default {
  updateFooterState({ footerState }, payload) {
    Object.assign(footerState, payload)
  },
  changeBtnContainerWidth({ footerState }, width) {
    footerState.btnContainerWidth = width
  },
  updatePriceDetailVisible({ footerState }, visible) {
    footerState.isPriceDetailVisible = visible
  }
}
