import { VirtualAssetsTypes } from '@/public/src/pages/checkout_new/utils/constant'

export default () => ({
  virtualAssetsState: {
    // 优惠券首屏使用
    isShowCouponError: false,

    // 券赠品抽屉
    couponGiftDrawer: {
      visible: false,
    },

    // 礼品卡抽屉
    giftCardDrawer: {
      visible: false,
    },


    // 钱包抽屉显示隐藏
    walletDrawer: {
      visible: false,
      /**
       * 输入框内容
       *
       * 请求响应之前, 前端状态： input
       *    响应之后, 以后端状态为准
       */
      value: '',
    },

    // 积分抽屉显示隐藏
    pointDrawer: {
      visible: false,
      /**
       * 输入框内容
       *
       * 请求响应之前, 前端状态： input
       *    响应之后, 以后端状态为准
       */
      value: '',
    },

    // 礼品卡导致优惠券报错弹窗
    couponErrorDialogByGiftCard: {
      visible: false,
      err: null
    },

    // 商品不可用弹窗
    freezeLimitGoods: {
      visible: false,
      type: VirtualAssetsTypes.COUPON,
    }
  }
})
