import { ShipGoodsInvalidType } from '@/public/src/pages/checkout_new/utils/constant'
import useWakeAddressWrite from '@/public/src/pages/checkout_new/pages/address_bar/hooks/useWakeAddressWrite.js'
import { OPERATE_TYPE, TRANSPORT_TYPE } from '@/public/src/pages/checkout_new/pages/address_bar/utils/constant.js'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useShippingSelect } from '@/public/src/pages/checkout_new/pages/shipping_method/hooks/useShippingSelect'
import { useMapState, useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'

export default {
  async handleShippingMethodResp({ state, commit }, { code: _code, info = {}, params }) {
    const { onAddressChange } = useShippingSelect()
    const { goodsLimit } = useMapState(['shippingMethodState.popup.goodsLimit'])
    const { updateLimitNum } = useMapMutations([ 'updateLimitNum'])
    
    try {
      info = info || {}
      info.shipping_pre_interception = info?.shipping_pre_interception || {}
      // https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1424675430
      // https://soapi.sheincorp.cn/application/7942/routes/post_order_order_checkout/doc
      const { shipping_pre_interception } = info
      const { limit_info: _limit_info, mall_code, transport_type, action, suggest_shipping_method, address_edit } = shipping_pre_interception
      let limit_info = _limit_info || {}
      let code  = +_code
      let hintType = +limit_info.hint_type
      const { handleAddressDrawer } = useWakeAddressWrite()
      const saTrackLimit = () => {
        daEventCenter.triggerNotice({
          id: 'expose_interception_shippingmethods:simple',
          data: {
            rules_type: hintType || '',
            no: goodsLimit.value?.no || '',
          }
        })
      }
      
      daEventCenter.triggerNotice({
        id: 'click_interception_shippingmethods:simple',
        data: {
          rules_type: hintType || '',
          shipping_method_id: transport_type,
          mall_code: mall_code
        }
      })
      
      updateLimitNum(goodsLimit.value.no + 1)
      
      if(code === 10126003) {
        // 1.商品类限制弹窗， 当且仅当hintType为2可编辑数量
        if(hintType === 1 || hintType === 2) {
          commit('updateShipPopupState', {
            goodsLimit: {
              invalidType: hintType === 1 ? ShipGoodsInvalidType.GOODS_LIMIT_NO_EDIT : ShipGoodsInvalidType.GOODS_LIMIT_CAN_EDIT,
              limitInfo: {
                popupTip: limit_info?.popup_tip || [],
                title: limit_info?.title || [],
                matchCarts: limit_info?.match_carts || [],
                mallCode: mall_code || '',
                transportType: transport_type
              }
            }
          })
          return
        }
        
        // hintType为3，地址类
        commit('updateShipPopupState', {
          goodsLimit: {
            invalidType: ShipGoodsInvalidType.GOODS_LIMIT_ADDR_INVALID,
            limitInfo: {
              title: limit_info.title || '',
              popupTip: limit_info.popup_tip || '',
              mallCode: mall_code || '',
              transportType: transport_type,
              address: limit_info.address,
            }
          }
        })
        
        saTrackLimit()
        return
      }
      
      // 3. 店配地址不匹配, hintType = 7
      if(code === 10126005) {
        if(action === 'shop_address_edit') {
          handleAddressDrawer({
            status: true,
            operateType: OPERATE_TYPE.EDIT,
            addressType: TRANSPORT_TYPE.SHOP,
            addressData: address_edit.address,
            callBack: params => onAddressChange(params, true)
          })
        } else if(action === 'suggest_shipping_method_popup') {
          commit('updateShipPopupState', {
            goodsLimit: {
              invalidType: ShipGoodsInvalidType.STORE_ADDR_NO_MATCH,
              limitInfo: {
                popupTip: suggest_shipping_method.popup_tip || '',
                mallCode: mall_code || '',
                transportType: transport_type,
                suggest_shipping_method,
              }
            }
          })
          
          daEventCenter.triggerNotice({
            id: 'expose_store_popup:simple',
          })
        }
        
        saTrackLimit()
        return
      }
      
      // 4. 宅店配切换,店配地址相关报错
      if([402502, 402501, 403370, 401906, 401973, 400322, 401972].includes(+_code)) {
        handleAddressDrawer({
          status: true,
          operateType: OPERATE_TYPE.ADD,
          addressType: TRANSPORT_TYPE.SHOP,
          addressData: state?.checkout?.address || {},
          extraData: {
            mallCode: mall_code,
            transportType: transport_type,
            needEmptyAddress: true, // 是否需要空地址
            closeIconClickFunc: () => {
              // 关闭填写抽屉需要执行
              commit('updateAddressRelativeError', false)
            }
          },
          callBack: onAddressChange,
        })
        return
      }
      
      if([10126001, 10126002].includes(code)) {
        // 宅店配互斥弹窗
        commit('updateShipPopupState', {
          goodsLimit: {
            invalidType: 10126001 === code ? ShipGoodsInvalidType.STORE_SWITCH_MUTEX_TO_STORE : ShipGoodsInvalidType.STORE_SWITCH_MUTEX_TO_HOME,
            params
          },
        })
      }
    } catch(e) {
      console.error(e)
    }
  }
}
