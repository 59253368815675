import { CombinationMode } from '@/public/src/pages/checkout_new/utils/constant'
import checkoutParams from './checkoutParams'

export default () => ({
  // 语言包
  language: {},

  // Apollo配置
  apolloConfig: {},

  // abt信息
  abtInfo: {},

  // checkout接口info回参
  checkout: {},

  // locals
  locals: {},

  // 用户信息
  userInfo: {},

  // 购物车接口info回参【页面多处使用】
  cartsInfo: {},

  // 立即购单号(url上拼接)
  buyNowNo: '',
  buyNowFromUrl: '',

  // bnpl前置选中支付方式(url上拼接)
  urlPaymentCode: '',

  // 下单页缓存
  cacheInfo: {},

  // 页面类型
  ssrPageType: '',

  originalUrl: '',

  prerenderSsr: false,

  env: '',

  // 首次在ssr请求checkout的参数
  initialSsrParamsInfo: {},

  /**
   * @description 站点镜像【ar il站点是true。其他是false】
   * @value {boolean} true ar il站点
   * @value {boolean} false ar 其他站点
   * */
  GB_cssRight: false,

  /**
   * @description 是否是多mall
   * @value {boolean} true 多mall
   * @value {boolean} false 单mall
   * */
  isMultiMall: false,

  /**
   * @description 布局模式
   * @value {CombinationMode.Combination} 聚合模式
   * @value {CombinationMode.Separation} 分离模式
   * */
  layoutMode: CombinationMode.Separation,

  // 页面中的价格总计栏在滚动过程中是否展示到视图
  isPagePriceTotalShowInView: false,

  /**
   * @description checkout接口入参 State ** 参数多，每次调用非全量，单独一个state **
   * */
  ...checkoutParams(),

  // 当前页加载(页面中)的 Loading，timer延时800ms展示
  checkoutLoading: {
    requesting: false, // 是否正在进行checkout接口请求
    loading: false, // 是否展示loading
    timer: null
  },

  // 成单接口请求ing
  createOrderLoading: false,

  // 是否是下线的状态，控制部分toast提示等
  isOfflineStatus: false,

  nowCurrency: '',

  // 是否点击过qs跨境运费
  isClickedQsFreight: false,

  paymentLoadingState: {
    newCheckoutLoadingType: '' // create-order | order-to-pay | pay-success | verification-successful
  },

  // checkoutNotice
  checkoutNotice: {}
})
