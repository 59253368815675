export default {
  updateSelectedPaymentInfo(state, payload) {
    if (payload?.payment_code?.length === 0) {
      state.paymentState.selectedPaymentInfo = {}
      return
    }
    state.paymentState.selectedPaymentInfo = Object.assign({}, payload)
  },
  assignPaymentState(state, payload) {
    state.paymentState = Object.assign({}, state.paymentState, payload)
  },
  mergeClickContinueResultInfoState(state, payload = {}) {
    state.paymentState.clickContinueResultInfo = Object.assign({}, state.paymentState.clickContinueResultInfo, payload)
  }
}
