// 取值与中间层保持一致
export const CardType = {
  /* 公共 */
  BOTH: 'both',
  /* 付费会员 */
  PRIME: 'prime',
  /* 超省卡 */
  SAVE: 'save',
}

export const DaRenewType = {
  [CardType.SAVE]: '1',
  [CardType.PRIME]: '2'
}

// 触发付费会员勾选的地方
export const SelectPrimeLocation = {
  // 1 - 随单购
  PAGE: '1',
  // 2 - 详情弹窗
  DRAWER: '2',
  // 3 - 切换支付方式调起的切包弹窗
  PAYMENT: '3',
  // 4 - 下单页挽留弹窗(已废弃)
  RETAIL_DIALOG: '4' 
}

// 付费会员详情弹窗触发的地方
export const PrimeDrawerLocation = {
  // 1 - 随单购模块
  PAGE: '1',
  // 2 - 物流运输方式
  SHIPPING: '2',
  // 3 - 下单页挽留弹窗(已废弃)
  RETAIL_DIALOG: '3' 
}
