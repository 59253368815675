// 常量
export const TRANSPORT_TYPE = {
  HOME: 'home', // 宅配
  SHOP: 'shop', // 店配
}

export const OPERATE_TYPE = {
  ADD: 'add', // 新增
  EDIT: 'edit', // 编辑
  DELETE: 'delete', // 删除
  SUPPLEMENT: 'supplement', // 地址补充
}

export const ADDRESS_TIPS_DIALOG_TYPE = {
  ADDRESS_STORE: 'ADDRESS_STORE', // 填写店配地址提醒
  ADDRESS_TW: 'ADDRESS_TW', // 台湾地区地址提醒弹窗
  ADDRESS_ADD: 'ADDRESS_ADD' // 地址超过20条的弹窗提醒
}

// 从 apollo 中获取的地址错误编辑弹窗 [402003, 402004, 402005, 402007]
export const ADDRESS_ERROR_CODE_EDIT_POPUP = typeof window !== 'undefined' ? window?.gbCommonInfo?.ADDRESS_ERROR_CODE_EDIT_POPUP || [] : []

// 下单地址错误码
export const ADDRESS_ERROR_CODE_ORDER = [401996, ...ADDRESS_ERROR_CODE_EDIT_POPUP]

// 地址错误码 30 开头的
export const ADDRESS_ERROR_CODE_30 = [300353, 300354]

// 店配地址错误码
export const ADDRESS_ERROR_CODE_SHOP = [402501, 402502, 403370, 401906, 401972, 401973, 400322]

// 地址错误码 40 开头的
export const ADDRESS_ERROR_CODE_40 = [400319, 401959, 401960, 401970, 401971, 401974, 401986, 401914, 401984, 401977, 400322, 402000, 401997, 401998, 401999, 402002, ...ADDRESS_ERROR_CODE_ORDER, 402006]



export default {
  TRANSPORT_TYPE,
  OPERATE_TYPE,
  ADDRESS_TIPS_DIALOG_TYPE,
  ADDRESS_ERROR_CODE_30,
  ADDRESS_ERROR_CODE_40,
  ADDRESS_ERROR_CODE_SHOP,
  ADDRESS_ERROR_CODE_ORDER,
}

