export const useFindPayment = (data, predicate) => {
  const queue = [...data] // 使用队列来处理广度优先搜索
  while (queue.length > 0) {
    const node = queue.shift() // 获取并移除队列的第一个元素
    if (node && predicate(node)) return node // 找到匹配的节点，返回该节点
    // 将子节点追加到队列
    if (node?.payments && node?.payments?.length > 0) {
      queue.push(...node.payments)
    }
  }

  return null // 没有找到匹配的节点，返回 null
}
