
export default {
  fullShippingMethodsSelectedInfo({ checkout, shippingMethodState }) {
    return shippingMethodState.selectedShippingList.reduce((memo, item) => {
      const currMall = checkout.shipping_methods_mall.find(mall => +item.mall_code === +mall.mall_code)
      
      // 点击滚动到指定位置需要
      const index = currMall.shipping_methods.findIndex(shipMethod => shipMethod.transport_type === item.transport_type)
      const shippingItem = index >= 0 ? currMall.shipping_methods[index] : {}
      
      return {
        ...memo,
        [item.mall_code]: {
          isStoreType: +shippingItem.is_shop_transit === 1,
          ...item,
          index,
          title: item.title
        },
      }
    }, {})
  },
  isFreePrice (state) {
    return state.checkout?.actual_shipping_price?.amountWithSymbol && +state.checkout?.actual_shipping_price?.amount === 0
  },
}
