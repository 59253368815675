import schttp from 'public/src/services/schttp'
import { abtservice } from 'public/src/services/abt'
import { dealProductsPretreatInfo } from '@/public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import { getLocalStorage } from '@shein/common-function'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'

const cache = {
  abtInfo: null,
  cardConfig: null,
  language: null,
}
let loginStatus = -1
// 判断登录状态是否一致
const getLoginStatusIsSame = () => {
  let status = isLoginFn()
  if(loginStatus === -1) {
    loginStatus = status
  }

  if(status !== loginStatus) {
    loginStatus = status
    return false
  }

  return true
}

/* 获取券数据 */
export const getCouponInfo = (data) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/coupon/addItemInfo',
    data: data,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

export const getPageAbt = async (loginStatusIsSame) => {
  if (loginStatusIsSame && cache.abtInfo) {
    return cache.abtInfo
  }
  // 凑单 相关
  const newKeys = [
    'addnewhotJson', 
    'Cartshowcoupon', 
    'CartaddCouponBottomStyle', 
    'orderCouponNewUser',
    'CartaddPromotionBottomStyle',
    'Cartshowcoupon',
    'cartautopoints',
    'CartaddFeeds',
  ]
  // 推荐/商卡 相关
  const cardNewKeys = [
    'listgoodsCard',
    'listflashSale',
    'AllListStarReview',
    'listquickship',
    'listquickshipKey',
    'RecommendExpandPop',
  ]
  // eslint-disable-next-line
  cache.abtInfo = await abtservice.getUserAbtResult({ newPosKeys: [...newKeys, ...cardNewKeys].join(',') })
  return cache.abtInfo
}

export const getCardConfig = async (loginStatusIsSame) => {
  if (loginStatusIsSame && cache.cardConfig?.code == 0) {
    return cache.cardConfig
  }
  return schttp({
    url: '/api/productAtom/cardConfig/get',
    method: 'get',
    params: {
      newProductCard: true, // 是否新商卡 1  0
      column: 2, // 商卡一图几行  1 2 3
    }
  }).then(res => {
    cache.cardConfig = res
    return res
  })
}

export const getLanguage = async () => {
  if (cache.language && Object.keys(cache.language).length) {
    return cache.language
  }
  return schttp({
    url: '/api/common/language/get',
    params: { page: 'filter' }
  }).then(res => {
    cache.language = res
    return res
  })
}

const getAtomParams = (params, config) => {
  const {
    listgoodsCard,
  } = config?.abtInfo || {}

  const atomFields = {
    ...config.cardConfig?.fields,
    estimatedPrice: false, // 凑单弹窗不接入到手价
  }

  // 合规站点不展示销量标签
  if (typeof gbCommonInfo !== 'undefined' && gbCommonInfo.shein_suggested_sale_price?.[gbCommonInfo.SiteUID]) {
    Reflect.deleteProperty(atomFields, 'salesLabel')
  }

  return {
    atomFields,
    atomScene: {
      pageKey: 'page_add_cart',
      sceneKey: 'TWO_IN_A_ROW',
    },
    atomCccParams: {
      cateId: '111',
      displayWindowAbtBranch: listgoodsCard?.param?.listGoods || '',
    },
    caller: ''
  }
}

export const getListsV3 = (params, config = {}) => {
  const {
    resultType = '',
    limit,
    req_num,
    pageEnable,
    scene_id,
    pageNum,
    jsonRuleId,
    location,
    cateIds,
    goodsIds,
    include_tsp_id,
    exclude_tsp_id,
    goods_price,
    add_on_type,
    mall_code,
    sort,
    quickship_prior,
    stock_enough,
    adp,
    shop_id,
    ...other
  } = params
  const {
    listflashSale,
    AllListStarReview,
    listquickship,
    listquickshipKey,
  } = config?.abtInfo || {}
  let atomParams = {}
  if (config?.isAtom) {
    atomParams = getAtomParams(params, config)
  }
  const contextParams = {
    cate_ids: cateIds,
    goods_ids: goodsIds,
    goods_price,
    add_on_type,
    mall_code,
    sort,
    quickship_prior,
    stock_enough,
    shop_id,
    ...other,
  }
  // TR-27530支持外围传入指定商品id,只在第一页进行adp传参
  if (adp && adp.length) {
    if (pageNum > 1) {
      contextParams.adp = ''
    } else {
      contextParams.adp = adp instanceof Array ? adp.slice(0, 10).join(',') : adp
    }
  }
  // 不能传空字符串
  if (include_tsp_id) contextParams.include_tsp_id = include_tsp_id
  if (exclude_tsp_id) contextParams.exclude_tsp_id = exclude_tsp_id
  const data = {
    resultType,
    limit,
    req_num,
    pageEnable,
    scene_id,
    pageNum,
    jsonRuleId: JSON.stringify(jsonRuleId),
    location,
    newPreHandle: true,
    abtInfoV3: {
      newProductCard: true,
      listflashSale: listflashSale?.param?.listflashSale == 'flashSale', // 闪购腰带
      starReview: AllListStarReview?.param?.liststar,
      listQuickShip: listquickship?.param?.listquickship,
      listquickKey: listquickshipKey?.param?.listquickKey,
    },
    contextParams,
    ...atomParams,
  }
  return schttp({
    url: '/api/recommend/facadeAtom/get',
    method: 'POST',
    data,
  })
}

export const getIntegrityPromotionInfo = async (products) => {
  const ids = []
  const asyncPromotionIds = ['2', '14', '15', '24', '28']
  const new_products = []

  products?.forEach(item => {
    let new_item = { promotionInfo: [] }
    item.promotionInfo?.forEach(info => {
      const id = String(info.id)
      const typeId = String(info.typeId)
      if (asyncPromotionIds.includes(typeId) && !ids.includes(id)) {
        ids.push(id)
        new_item.promotionInfo.push({
          id,
          typeId,
        })
      }
    })
    if(new_item.promotionInfo.length) {
      new_products.push(new_item)
    }
  })
  
  if(!new_products.length) { 
    return {} 
  }
  const { data: promotionInfoFromServer = {} } = await schttp({
    method: 'POST',
    url: '/api/productInfo/postIntegrityPromotionInfo/get',
    data: {
      // asyncPromotionIds: '',
      // fetchedPromotionIds: '',
      products: new_products,
    }
  })
  return promotionInfoFromServer
}

export const getInterfaceListV3 = async (params, config = {}) => {
  const res = await getListsV3(params, { ...config, isAtom: true })
  const { info, code, msg } = res || {}
  let products = info?.products || []

  let promotionInfoFromServer
  try {
    promotionInfoFromServer = await getIntegrityPromotionInfo(products)
  } catch (error) {
    console.error('getIntegrityPromotionInfo', error)
  }

  if (promotionInfoFromServer) {
    products = dealProductsPretreatInfo({
      products,
      itemConfig: config?.itemConfig || {},
      promotionInfoFromServer,
      // language: {},
    })
  }

  return {
    products,
    total: info?.total || 0,
    code,
    msg,
    isFaultTolerant: false,
  }
}

export const getAddressInfo = () => {
  const addressCookie = getLocalStorage('addressCookie')
  const localCache = JSON.parse(localStorage.getItem('localCache')) || {}
  let addressInfo = {}
  if (addressCookie) {
    try {
      addressInfo = typeof addressCookie === 'string' ? JSON.parse(addressCookie) : addressCookie
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('获取地址缓存失败', error)
    }
  }
  return { ...addressInfo, ...localCache }
}

/**
 * 获取购物车接口通用参数
 * @param {object} params
 * @param {string} addType 凑单类型, 非必须的字段,https://wiki.dotfashion.cn/pages/viewpage.action?pageId=413794416
 * @param {string} auto_use_coupon 是否自动用券(0否 1是)默认为0
 * @param {object} couponFilterGoods 指定券在购物车顶部筛选凭证的商品信息集合， 这个只有TR-31366 才有加上的入参，也是因为它才把查车的get请求改为post请求。
 * @param {string} coupon_code 优惠券券码， 用于凑单组件跳转购物车时的toast提示
 * @param {string} currentRangeIndex 当前选择的档次信息
 * @param {string} filterTagId 前端传的筛选标签唯一id
 * @param {string} filter_tag 筛选标签枚举值，1：闪购， 2：降价， 3：售罄， 具体定义在CartFilterTagEnum类
 * @param {string} holdTopFilterTagIds 维持顶部筛选项固定位置筛选集合: 从二级页面回来，前端要继续保持登顶的筛选项id的传参，支持多个传参， 多个筛选值用逗号隔开。  目前只有券需要保持登顶，方案类似线上coupon/list接口券登顶的coupons传参
 * @param {string} njActivityId 免邮活动id, 用于免邮活动凑单重构
 * @param {string} njActivityType 免邮活动类型， 用于免邮活动凑单重构
 * @param {string} now_level 运费活动当前档次值, 用于免邮活动凑单重构
 * @param {string} transport_type 运输方式类型， 用于免邮活动凑单重构
 *
 */
const getCartCommonParams =  (params = {}) =>{
  const addressInfo = getAddressInfo()
  return {
    is_first: '0',
    address_id: addressInfo?.addressId,
    city: addressInfo?.city || '',
    country_id: addressInfo?.countryId,
    district: addressInfo?.district || '',
    isOpenScreenEfficiency: '1',
    postcode: addressInfo?.postcode || '',
    state: addressInfo?.state || '',
    userLocalSizeCountry: window.localStorage.getItem('last_select_country'),
    ...params
  }
}

/**
 * 新版mall-修改购物车内的商品属性
 * @param {string} abt_mark 全场景商品图AB测试
 * @param {string} append_id_list 附属品的购物车id集合
 * @param {string} attrs[0][attr_id] 
 * @param {string} attrs[0][attr_value_id] 
 * @param {string} auto_use_coupon 是否自动用券(0否 1是)默认为0
 * @param {string} auto_use_point 是否自动用积分 0不自动用 1自动用 默认为0
 * @param {string} auto_use_point_threshold 自动使用的积分最低门槛
 * @param {boolean} checked 
 * @param {string} filter_tag 筛选标签枚举值，1：闪购， 2：降价， 3：售罄， 具体定义在CartFilterTagEnum类
 * @param {string} goods_id 
 * @param {string} id 购物车id(主品id)
 * @param {string} is_checked 商品勾选状态(0:未勾选 1:已勾选)
 * @param {string} mall_code 市场编码
 * @param {string} promotion_id 促销活动ID
 * @param {string} promotion_product_mark 促销活动主附属品标识
 * @param {string} promotion_type 促销活动类型
 * @param {string} quantity 数量
 * @param {string} sku_code sku_code码
 *
 */
export const cartUpdateAttr =  (params) => {
  let data = getCartCommonParams(params)
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/mall/cart_update_attr',
    data: {
      ...data,
      is_old_version: 0,
      is_direct_request_pmsc: 1,
    },
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

export const fetchAddOnABTLanguageCardConfig = () => {
  let loginStatusIsSame = getLoginStatusIsSame()
  return Promise.all([getPageAbt(loginStatusIsSame), getCardConfig(loginStatusIsSame), getLanguage()])
}
