import Vue from 'vue'
import Vuex from 'vuex'
import { stateFactory } from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const store = {
  namespaced: true,
  state: stateFactory(),
  getters,
  mutations,
  actions
}

// For MPA SSR
export function createStore() {
  Vue.use(Vuex)
  return new Vuex.Store({ modules: { checkout: store } })
}

// For SPA
export default store
