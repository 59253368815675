import { getCookie, setLocalStorage } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
const { default_currency, lang } = gbCommonInfo

const CURRENCY_COOKIE_KEY = 'currency'
const LANGUAGE_COOKIE_KEY = 'language'
const MAX_AGE = 1000 * 60 * 60 * 24 * 20  //20天

export const getLanguageConfig = async () => {
  const res = await schttp({
    url: '/user/language/list',
    useBffApi: true
  })
  const langArr = res?.info?.language_list
  let value = langArr?.[0]?.web_lang || lang
  // eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
  let cookieValue = getCookie(LANGUAGE_COOKIE_KEY)
  if( cookieValue && langArr.some(_ => _.web_lang == cookieValue) ){
    value = cookieValue 
  }
  return {
    langValue: value,
    calMultipleLang: { langArr }
  }
}

export const setLanguage = async (value = 'en') => {
  await schttp({
    url: `/user/language/change?new_lang=${value}`,
    useBffApi: true
  })
}

export const getCurrency = async () => {
  const res = await schttp({
    url: '/user/currency/list',
    useBffApi: true
  })
  const SUPPORT_CURRENCIES = res?.info?.currency_infos || []

  //由于cookie可能是其它站点设置，这里的cookie值必须为支持值，
  let value = default_currency
  // eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
  let cookieValue = getCookie(CURRENCY_COOKIE_KEY)
  //cookie必须是当前站点支持值
  if(cookieValue && SUPPORT_CURRENCIES.some(_ => _.currency_code = cookieValue)){
    value = cookieValue 
  }
  return {
    value,
    list: SUPPORT_CURRENCIES
  }
}

export const setCurrency = async (value, ignoreReload = false) => {
  //提供给用增游戏等其它pwa项目获取货币
  setLocalStorage({ key: CURRENCY_COOKIE_KEY, value: value, expire: MAX_AGE })

  const res = await schttp({
    url: `/user/currency/change?currency=${value}`,
    useBffApi: true
  })
  if (res.code == '0') {
    setLocalStorage({
      key: `manualChangeCurrency_${lang}`,
      value: Date.now(),
      expire: MAX_AGE
    })
    !ignoreReload && location.reload()
  }
}
