import { computed } from 'vue'
import { useMapActions, useMapMutations, useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { cloneDeep, debounce } from 'lodash'

let hooks = null

function shippingSelectFactory() {
  const { checkout, selectedShippingList, initialSsrParamsInfo, histories, lastAddress } = useMapState(['checkout', 'shippingMethodState.selectedShippingList', 'initialSsrParamsInfo', 'shippingMethodState.histories', 'shippingMethodState.lastAddress'])
  const { selectShippingMethod, recoverLastShippingMethod, resetShippingLimits, updateAddressRelativeError, updateShippingState }
        = useMapMutations(['selectShippingMethod', 'recoverLastShippingMethod', 'resetShippingLimits', 'saveSuccessShippingParams', 'updateAddressRelativeError', 'updateShippingState'])
  const { updateCheckoutData, updateCartsInfoData, updateStock } = useMapActions(['updateCheckoutData', 'updateCartsInfoData', 'updateStock'])
  
  const findShippingMethod = (mallCode, transportType) => {
    const currMall = checkout.value.shipping_methods_mall.find(mall => +mallCode === +mall.mall_code)
    return currMall.shipping_methods.find(shipMethod => shipMethod.transport_type === transportType)
  }
  
  /**
   *
   * @param {Array<Object>} selectedList 选中项，数组对象。
   * @returns {-1, 0, 1} -1: 多mall存在且没有一个选中项；0: 非店配；1: 店配
   */
  const getSelectedIsStore = (selectedList = []) => {
    if(!selectedList || selectedList.length === 0) return -1

    // 当前选中/修改的运输方式
    let beingCheckMall = selectedList[0]
    
    if(selectedList.length > 1) {
      beingCheckMall = selectedList.find(item => +item.being_check === 1) || selectedList[0]
    }

    const { mall_code, transport_type } = beingCheckMall
    
    const shipItem = findShippingMethod(mall_code, transport_type)
    
    return +shipItem?.is_shop_transit === 1 ? 1 : 0
  }
  
  const lastShipIsStoreType = computed(() => {
    const len = histories.value.length
    const item = len > 1 ? histories.value[len - 2] : null
    if(item == null) return -1
    return getSelectedIsStore(item)
  })
  
  const currentIsStoreType = computed(() => {
    const len = histories.value.length
    const item = len > 0 ? histories.value[len - 1] : null
    if(item == null) return -1
    return getSelectedIsStore(item)
  })
  
  const getShippingMethodsParams = ({ noBeingCheck } = {}) => {
    const list = selectedShippingList.value
    
    return {
      shipping_methods: !noBeingCheck ? list : list.map(item => ({ ...item, being_check: 0 })),
      shop_transit_country_id: currentIsStoreType.value ? checkout.value?.address?.countryId || initialSsrParamsInfo.value?.country_id  : ''
    }
  }
  
  const resetNormalOnSuccess = () => {
    resetShippingLimits()
    updateAddressRelativeError(false)
  }
  
  // 运输方式选中逻辑
  const onShippingSelect = async ({ mallCode, transportType, params = {}, isItemClick = false }) => {
    const shippingItem = findShippingMethod(mallCode, transportType)
    
    if(!mallCode || !transportType) {
      console.error('mallCode、transportType 缺少参数')
      return false
    }
    
    if(!shippingItem) {
      return false
    }
    
    if(+shippingItem.isAvailable === 0) {
      return false
    }

    // // 只有宅配可以唤醒拦截弹窗
    // if (+shippingItem.unavailable_type === 9 && +shippingItem?.is_shop_transit == 0 && !isHasAddress.value) {
    //   updateAddressBarState({
    //     noAddressRemindDialog: {
    //       show: true,
    //       title: false,
    //       type: +shippingItem?.is_shop_transit === 1 ? TRANSPORT_TYPE.SHOP : TRANSPORT_TYPE.HOME
    //     }
    //   })
    //   return false
    // }
    
    selectShippingMethod({
      mallCode,
      transportType
    })

    const opts = {
      ...params,
      ...getShippingMethodsParams()
    }
    
    if(isItemClick) {
      let newVal = [...histories.value, cloneDeep(opts.shipping_methods)]
      
      if(newVal.length > 4) {
        newVal = newVal.slice(-4, newVal.length)
      }
      
      updateShippingState({
        histories: newVal
      })
    }
    
    // 运输方式切换时，报错显示上一项的地址
    updateAddressRelativeError(true)
    
    // order/order/checkout接口入参梳理 https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1436715107
    const res = await updateCheckoutData({ opts })
    
    if(+res.code === 0) {
      resetNormalOnSuccess()
    }
  }
  
  // 提供地址列表callback
  const addressCallback = (res, addressData) => {
    updateStock({
      outStockCarts: checkout.value?.outStockCarts,
      outStockCartsTip: checkout.value?.outStockCartsTip
    })
    
    if(addressData) {
      updateShippingState({
        lastAddress: addressData
      })
    }
    
    if(+res.code === 0) {
      resetNormalOnSuccess()
    }
  }
  
  // 提供给地址hooks的回调（public/src/pages/checkout_new/pages/address_bar/hooks/useWakeAddressWrite.js）
  const onAddressChange = async (addrCbParams = {}, noBeingCheck = false) => {
    const { statusCode, addressData } = addrCbParams
    
    if(+statusCode !== 0) {
      return false
    }
    
    updateShippingState({
      lastAddress: addressData
    })
    
    const {
      state, city, postcode, addressId: address_id, countryId: country_id,
    } = addressData
    
    const opts = {
      ...getShippingMethodsParams({ noBeingCheck }),
      state, city, postcode,
      address_id,
      country_id,
    }
    // order/order/checkout接口入参梳理 https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1436715107
    const res = await updateCheckoutData({ opts })
    updateCartsInfoData({ country_id })
    
    addressCallback && addressCallback(res)
  }
  
  // 运输方式切换失败回退
  const rollbackShippingMethod = async ({ noBeingCheck } = {}) => {
    const lastItem = histories.value.length > 1 ? histories.value[histories.value.length - 2] : undefined
    
    if(!lastItem) {
      return null
    }
    
    updateShippingState({
      histories: histories.value.slice(0, -1)
    })
    
    recoverLastShippingMethod({
      shippingMethodList: lastItem
    })
    
    const {
      state, city, postcode, addressId: address_id, countryId: country_id,
    } = lastAddress.value || {}
    
    const opts = {
      ...getShippingMethodsParams({ noBeingCheck }),
      ...lastAddress.value ? {
        state, city, postcode,
        address_id,
        country_id,
      } : null
    }
    const res = await updateCheckoutData({ opts })
    
    if(+res.code === 0) {
      resetNormalOnSuccess()
    }
  }
  
  // 凑单失败回退运输方式
  const addItemRollback = async (snapshot) => {
    recoverLastShippingMethod({
      shippingMethodList: snapshot
    })
    const opts = {
      ...getShippingMethodsParams(),
    }
    const res = await updateCheckoutData({ opts })
    if(+res.code === 0) {
      resetNormalOnSuccess()
    }
  }
  
  // debug
  // window.rollbackShippingMethod = rollbackShippingMethod
  //
  // watch(currentIsStoreType, (val) => {
  //   console.info('[useShippingSelect]currentIsStoreType is ', val)
  // })
  //
  // watch(lastShipIsStoreType, (val) => {
  //   console.info('[useShippingSelect]lastShipIsStoreType is ', val)
  // })
  //
  // watch(histories, (val) => {
  //   console.info('[useShippingSelect]histories is ', val)
  // })
  
  return {
    currentIsStoreType,
    lastShipIsStoreType,
    onAddressChange,
    onShippingSelect: debounce(onShippingSelect, 80),
    rollbackShippingMethod: debounce(rollbackShippingMethod, 80),
    findShippingMethod,
    getShippingMethodsParams,
    updateCheckoutData,
    addressCallback,
    resetNormalOnSuccess,
    addItemRollback
  }
}

export function useShippingSelect () {
  if(!hooks) {
    hooks = shippingSelectFactory()
  }
  
  return hooks
}
