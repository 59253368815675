import { getCurrentInstance, computed } from 'vue'
import { get } from 'lodash'

let instance = null

export const useStore = () => {
  if (!instance) {
    instance = getCurrentInstance()
  }

  if (!instance) return console.warn('useStore must be used after setup')
  return instance?.proxy?.$store
}

export function useMapState(mapper, moduleName = 'checkout') {
  const store = useStore()
  const storeState = {}

  mapper.forEach(item => {
    const groups = item.split('.')
    const last = groups[groups.length - 1]
    storeState[last] = computed(() => {
      return get(store.state[moduleName], item)
    })
  })

  return storeState
}

export const useCheckoutState = (stateKeys, moduleName = 'checkout') => {
  const store = useStore()
  return Object.fromEntries(
    stateKeys.map(key => [key, computed(() => store.state[moduleName][key])])
  )
}

export const useMapMutations = (mutationKeys, moduleName = 'checkout') => {
  const store = useStore()
  return Object.fromEntries(
    mutationKeys.map(mutation => [
      mutation,
      value => store?.commit(`${moduleName}/${mutation}`, value)
    ])
  )
}

export const useMapGetters = (getterKeys, moduleName = 'checkout') => {
  const store = useStore()
  return Object.fromEntries(
    getterKeys.map(getter => [
      getter,
      computed(() => store?.getters[`${moduleName}/${getter}`])
    ])
  )
}

export const useMapActions = (actionKeys, moduleName = 'checkout') => {
  const store = useStore()
  return Object.fromEntries(
    actionKeys.map(action => [
      action,
      value => store.dispatch(`${moduleName}/${action}`, value)
    ])
  )
}
