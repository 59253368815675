
export default {
  limitGoods({ shoppingBagsState, checkout }) {
    const { bagsInfo } = shoppingBagsState
    const { mall_store_infos } = checkout
    
    const flatMallStoreInfos = mall_store_infos.reduce((memo, item) => {
      const { sku_available_infos, ...rest } = item
      
      sku_available_infos.forEach(info => {
        memo.push({
          ...rest,
          ...info
        })
      })
      
      return memo
    }, [])

    return bagsInfo.all.reduce((memo, item) => {
      const matchItem = flatMallStoreInfos.find(inner =>
        +item.mall_code === +inner.mall_code && item.skuCode === inner.sku_code)
      
      if(!matchItem) return memo
      
      // wallet_available 钱包是否可用, 0、不可用 1、可用 不用礼品卡/钱包时默认null
      if (+matchItem.wallet_available === 0) {
        memo.wallet.push(item)
      }
      
      // gift_card_available 礼品卡是否可用, 0、不可用 1、可用 不用礼品卡/钱包时默认null
      if (+matchItem.gift_card_available === 0) {
        memo.giftCard.push(item)
      }
      
      return memo
    }, {
      wallet: [],
      giftCard: []
    })
  },
  limitGoodsPoint({ checkout, shoppingBagsState }) {
    const { point } = checkout
    const { useRuleDetail: { useRules } } = point
    
    return useRules.map(rule => {
      return {
        title: rule.title,
        goodsList: rule.goodList.map(({ cartId }) => {
          return shoppingBagsState.bagsInfo.all.find(item => item.id === cartId)
        }).filter(Boolean)
      }
    })
  }
}
