import Vue from 'vue'
import store from './store'

import { 
  Toast, 
  Loading, 
  Drawer, 
  AdapterText,
} from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import Helper from './utils/helper'
import { fetchAddOnABTLanguageCardConfig } from 'public/src/pages/common/add-on/utils/fetcher.js'

Vue.prototype.$toast = Toast
Vue.component(Loading.name, Loading)
Vue.component(Drawer.name, Drawer)
Vue.component(Icon.name, Icon)
Vue.component(AdapterText.name, AdapterText)

let instance
if(typeof window !== 'undefined') {
  requestIdleCallback(() => {
    window.preFetchAddOnABTLanguageCardConfig = fetchAddOnABTLanguageCardConfig()
  })
}

export default {
  open(config) {
    if (Vue.prototype.$isServer) return

    if (instance) {
      return instance.open(config)
    }

    return new Promise((resolve) => {
      import(/* webpackChunkName: "add_on_v2" */ './container.vue').then((components) => {
        const vm = new Vue({
          render (h) {
            return h(components.default)
          },
          store,
        }).$mount()
        document.body.appendChild(vm.$el)
        const comp = vm.$children[0]
        comp.open(config)
        instance = comp

        resolve(instance)
      })
    })
  },
  updateOpts(options) {
    if (!instance) return
    instance.updateOpts(options)
  },
  close() {
    if (!instance) return
    instance.handleClose()
  },
  Helper,
}
