import { QsFreightType } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils/constant'

export default () => ({
  shoppingBagsState: {
    /**
     * @description 商品项信息
     * @all {Array} 商品列表所有数据
     * @mall {Array} mall维度的数据
     * @normal {Array} 非QS商品列表
     * @quickShip {Array} QS商品列表
     * @isRenderSingleProduct {Boolean} 渲染单商品组件【如何渲染，何时渲染，请查看数据映射关系处理】数据映射 public/src/pages/checkout_new/store/mutations/dataMapping.js
     * @totalQuantity {normal, quickShip} 数量【分QS和非QS】
     * */
    bagsInfo: {
      all: [],
      mall: [
        {
          all: [],
          normal: [],
          quickShip: [],
          isRenderSingleProduct: false,
          totalQuantity: {
            normal: 0,
            quickShip: 0
          }
        }
      ]
    },

    /**
     * @description 商品总数量
     * @normal {Number} 非QS商品总数量
     * @quickShip {Number} QS商品总数量
     * */
    totalQuantity: {
      normal: 0,
      quickShip: 0
    },

    /**
     * @description qs跨境运费Radio状态
     * @value {1} 1表示勾选
     * @value {2} 2表示不勾选
     * */
    qsFreightStatus: QsFreightType.false,

    /**
     * @description 是否展示巴西50美金税费提示入口
     * */
    isShowMallHintInfo: false,

    // 是否展示删除购物袋商品项的二次确认弹窗
    isShowShoppingBagsItemDeleteDialog: {
      visible: false,
      cart_id: '',
      isDefaultDoubleConfirm: false
    },

    // 更改qs商品的数量时，是否超过了当前库存，超过则需要弹窗
    isShowShoppingBagsItemOverStockDialog: {
      visible: false,
      stock: 0,
      params: {}
    },

    // 是否重置商品项的quantity的数据
    isResetShoppingBagsItemValue: false,

    // 巴西50美金税费弹窗状态
    visibleBr50DollarsTaxDrawer: false,

    // 订单详情弹窗显隐状态
    visibleOrderDetailDrawer: {},

    // 0：仅自营；1：仅店铺；2：既有店铺也有自营
    isShopType: 0,

    // /order/shoppingBag/update 接口的返参，作为下一次请求的入参
    originalPriceGoodsIdList: [],

    // 是否禁用inputNumber输入框
    isDisabledInput: false,

    // 阻止购物袋排序
    isBlockingSort: false
  }
})
