export default () => ({
  addressBarState: {
    defaultAddress: null,
    defaultAddressCache: null, // 默认地址中间态, 用于暂存地址,局限于地址栏的显示
    addressCount: 0, // 当前地址的总数
    nationalIdTip: null,
    addressTip: null,
    addressTipsDialog: {
      visible: false,
      type: '', // 全局搜索, 从 ADDRESS_TIPS_DIALOG_TYPE 字段中取值
    },
    cacheSaPageInfo: null, // 用在地址列表打开或者关闭中，作为埋点使用
    addressWriteDrawer: { // 地址填写弹窗信息，关闭弹窗的时候会全部还原
      status: false, // 控制地址填写弹窗打开还是关闭
      operateType: '', // 操作类型 add/edit/delete/supplement
      addressType: '', // 地址类型 home（宅配）/shop（店配）
      addressData: {}, // 地址的数据，用于地址列表编辑/补充等
      extraData: {}, // 其他的数据，自行封装
      callBack: () => { }, // 回调函数，这个callback只有在add、edit、supplement后会触发，如果有其他的callBack请写在extraData中
    },
    isLoadedAddressWriteSuccess: false, // 用于控制AddressWrite组件加载成功，拉起地址填写弹窗，用于在无地址的typeB
    isHasBeenNoAddressDrawerShow: false, // 在无地址的情况下，控制当前地址弹窗是否已经展示，防止用户点击后，弹窗再出现，属于优化点

    isCheckSpecialGoods: false, // 南非校验特殊商品下单地址是否存在身份id
    
    addressListDrawer: {
      status: false, // 是否打开地址列表
      selectedAddress: {}, // 地址列表选中的数据
      extraData: {}, // 在地址列表内打开编辑/添加等操作地址抽屉，保存后请求计价的额外参数
      callBack: () => {}, // 在地址列表内打开编辑/添加等操作地址抽屉，保存后请求计价的额外回调函数
    },

    // 无地址提醒弹窗控制信息
    noAddressRemindDialog: {
      show: false,
      title: false,
      type: '', // 唤醒店配/宅配填写地址页面
    }
  }
})
