export default {
  // 控制随手购展示
  visibleBuyTogether({ abtInfo, buyNowNo, togetherBuy }) {
    const { APOLLO_CONFIG, togetherBuyType, OrderGoodsLocation } = togetherBuy || {}
    const configLocation = !!togetherBuyType ? +OrderGoodsLocation : 0


    const isNewUserCasualBuy = abtInfo?.NewUserCasualBuy?.param?.NewUserCasualBuy === 'new'
    const isOpenApollo = APOLLO_CONFIG?.CHECKOUT_SWITCH_BUY_TOGETHER_NOW === 'on'

    // 控制展示
    const visible = isNewUserCasualBuy ? false : buyNowNo ? isOpenApollo : true

    // 控制渲染位置
    return visible && configLocation !== 0
  }
}
