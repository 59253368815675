export default () => ({
  footerState: {
    // 是否展示 footer 内的 “ 选择支付方式 ”
    isSelectPaymentMethod: true,

    // 分期付款总价格
    installmentTotalPrice: 0,

    // 当前利诱点气泡数据
    showLurePointPopoverData: {},

    // 悬浮利诱点信息
    floatingInfo: {
      autoUseInfo: {}, // 自动用券提示（券、积分、钱包）
      unuseBenefitInfo: {}, // 未使用利诱点提示
      pickupBuyInfo: {}, // 随手购提示
      payGuideInfo: {}, // 支付悬浮提示
      type: '',
      initDone: false, // 是否执行完所有利诱点初始化判断
      alreadyShow: false // 展示过触发利诱点消失
    },

    // 支付按钮宽度
    btnContainerWidth: '',

    isShowBottomPaymentSelector: false, // 展示楼层置底

    // 是否展示价格详情抽屉
    isPriceDetailVisible: false
  }
})
