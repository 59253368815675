export default {
  selectedPrimeProduct(state) {
    const code = state.checkoutParams.prime_product_code
    if (!code) return
    return state.checkout.prime_info.prime_products.find(item => item.product_code === code)
  },
  selectedSaverProduct(state) {
    const code = state.checkoutParams.save_card_product_code
    if (!code) return
    return state.checkout.saveCardInfo.products.find(item => item.saveCardProductInfo.saveCardProductCode === code)
  }
}
