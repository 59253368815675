/**
 * @description: 设置地址相关的信息，包括默认地址、地址总数、提示等
 * @return {*}
 */
export const setAddressInfo = (state, { checkout }) => {
  const { address = {}, address_tip, nationalIdTip, addressCount = 0 } = checkout
  Object.assign(state, {
    addressBarState: {
      ...state.addressBarState,
      addressCount,
      nationalIdTip,
      defaultAddress: address,
      addressTip: address_tip,
    }
  })
}
