import commonState from './commonState'
import abtState from './abt'
import pageState from './page'
import shoppingBagsState from './ShoppingBags'
import headerState from './headerState'
import footerState from './footer'
import addressBarState from './addressBar'
import shippingMethodState from './shippingMethod'
import virtualAssetsState from './virtualAssets'
import paymentState from './pay'
import commonDialogState from './commonDialog'
import couponListState from './couponList'
import primeSaverState from './primeSaver'
import buyTogetherState from './buyTogether'

export const stateFactory = () => ({
  /**
   * @description 公共 State 存放公共的state ** 如果不确定是否公共则放在对应业务的state **
   * */
  ...commonState(),

  /**
   * 弹窗相关 state
   */
  ...abtState(),

  /**
   * @description 页面级别 State ** 页面操作相关的state **
   * */
  ...pageState(),

  /**
   * @description 购物袋 State
   * */
  ...shoppingBagsState(),

  /**
   * @description header State
   * */
  ...headerState(),

  /**
   * @description footer State
   * */
  ...footerState(),
  /**
   * @description address bar State
   * */
  ...addressBarState(),

  /**
   运输方式
   */
  ...shippingMethodState(),

  /**
   虚拟资产(优惠券/礼品卡/积分/钱包)
   */
  ...virtualAssetsState(),
  /**
   * 支付相关
   */
  ...paymentState(),

  /**
   * 弹窗相关 state
   */
  ...commonDialogState(),

  /**
   * 券列表接口返回券列表数据相关 state
   */
  ...couponListState(),

  /**
   * 付费会员及超省卡相关 state
   */
  ...primeSaverState(),

  /**
   * 随手购 state
   */
  ...buyTogetherState(),
})
