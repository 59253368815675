import { watch, ref } from 'vue'

const isWriterReady = ref(false)

export const useDelayMount = (visible) => {
  const changeWriterToReady = () => {
    isWriterReady.value = true
  }
  
  if(visible) {
    watch(visible, (visible) => {
      if(visible && !isWriterReady.value) {
        changeWriterToReady()
      }
    })
  }

  return {
    isWriterReady,
    changeWriterToReady
  }
}
