// 建议 constant 命名用大写, 区分常量
// 国家编码
export const COUNTRY_OR_AREA_CODE = {
  // Belarus(白俄罗斯)
  BY: 20,
  // Chile(智利)
  CL: 43,
  // Israel(以色列)
  IL: 105,
  // Japan(日本)
  JP: 108,
  // Russian Federation(俄罗斯联邦)
  RU: 178,
  // Taiwan(台湾)
  TW: 209,
  // United States(美国)
  US: 226,
  // Saudi Arabia（沙特阿拉伯）
  SA: 186
}

// 购物袋和运输方式的组合方式
export const CombinationMode = {
  // mall维度聚合（多mall站点直接聚合)
  Combination: 'combination',

  // 购物袋与运输方式分开展示
  Separation: 'separation'
}

// CHECKOUT qs状态
export const CurrentQuickShipStatus = {
  // 正常航运
  NormalShip: '0',

  // 部分QS
  PartQuickShip: '1',

  // 全部QS
  AllQuickShip: '2'
}

// CARTS INFO ITEM qs状态
export const RealQuickShipStatus = {
  // 非QS商品
  Normal: '0',

  // QS商品【真实库存不足】
  PartQuick: '2',

  // QS商品【真实库存足】
  AllQuick: '1'
}

export const BUS_EVENTS = new Map()

export const ShipGoodsInvalidType = {
  // 默认，无异常
  NO_INVALID: 0,

  // 商品类限制弹窗
  GOODS_LIMIT_NO_EDIT: 1,

  // 商品类限制弹窗, 可编辑数量
  GOODS_LIMIT_CAN_EDIT: 2,

  // 地址类异常，hintType为3
  GOODS_LIMIT_ADDR_INVALID: 3,

  // 店配地址不匹配
  STORE_ADDR_NO_MATCH: 4,

  // 宅店配切换,店配地址相关报错
  STORE_SWITCH_EXCEPTION: 5,

  // 宅店配切换, 宅切店
  STORE_SWITCH_MUTEX_TO_STORE: 6,

  // 宅店配切换, 店切宅
  STORE_SWITCH_MUTEX_TO_HOME: 7
}

// quantity展示类型
export const QuantityType = {
  // 展示类型 === 形如 x3
  View: 'VIEW',

  // 输入类型 === 形如 InputNumber
  Input: 'INPUT'
}

export const VirtualAssetsTypes = {
  WALLET: 'wallet',
  GIFT_CARD: 'giftCard',
  COUPON: 'coupon',
  POINT: 'point'
}
