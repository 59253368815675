export default () => ({
  abtState: {
    // 新旧样式
    orderConfirmDiscountSwitch: false,
    placeOrderStatusMove: false,

    // 控制气泡展示的时机和气泡的文案
    isBubbleCopyWriting: false,
    isShowNewActivityPopoverLayout: true,
    isShowActivityPopoverNewLayout: false,
    // 开启总优惠金额展示
    isShowSaveAmount: false,
    placeOrderButtonPayStatus: false,
    bnplPlaceOrderStatus: false,
    placeOrderButtonUpgradeStatus: false,
    placeOrderFreeShippingstatus: false,
    PlaceOrderQuickshipWordStatus: false,
    LocationPrimeSaverStatus: false,

    // 填写地址的时候，显示的方式是跳转地址填写弹框
    isUsePopJumpAddressWrite: false,
    // 使用新版下单loading
    isUseNewCheckoutLoading: false,
    isOrderpaylistbottomstatus: false, // 支付方式楼层置底
    isOrderpaylistbottomnodefaultstatus: false, // 支付方式楼层置底, 又默认支付方式时用的 abt
    paymentSafetyExperimentAbt: {},
  }
})
