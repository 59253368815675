import Vue from 'vue'
import Vuex from 'vuex'

const getState = () => ({
  abtInfo: {},
  language: {},
  cardConfig: {},
  isFullScreen: false,
  progressDataPromise: null,
  addSize: 0,
})

Vue.use(Vuex)
const store = new Vuex.Store({
  state: getState(),
  getters: {
    couponBottomStyle(state) {
      const { cartadd_coupon_bottom_style } = state.abtInfo?.CartaddCouponBottomStyle?.param ?? {}
      return cartadd_coupon_bottom_style || 'A'
    },
    promotionBottomStyle(state) {
      const { cartadd_promotion_bottom_style } = state.abtInfo?.CartaddPromotionBottomStyle?.param ?? {}
      return cartadd_promotion_bottom_style || 'A'
    },
    feedsType(state) {
      const { cartpop_feeds_additems } = state.abtInfo?.CartaddFeeds?.param || {}
      return cartpop_feeds_additems || 'off' // off | on_A | on_B
    },
    autoUseCoupon(state) {
      return state.abtInfo?.Cartshowcoupon?.param?.cart_show_coupon_switch == 'on' ? '1' : '0'
    },
    autoUsePoint(state) {
      const [auto_use_point] = (state.abtInfo?.cartautopoints?.param?.is_auto_point || '').split('_')
      return auto_use_point == 0 ? '0' : '1'
    },
    jsonRuleId(state) {
      return state.abtInfo?.addnewhotJson?.param || {}
    }
  },
  mutations: {
    setAbtInfo(state, payload) {
      state.abtInfo = payload
    },
    setLanguage(state, payload) {
      state.language = payload
    },
    setCardConfig(state, payload) {
      state.cardConfig = payload
    },
    setIsFullScreen(state, payload) {
      state.isFullScreen = payload
    },
    setProgressDataPromise(state, payload) {
      state.progressDataPromise = payload
    },
    resetState(state) {
      const defaultState = getState()
      Object.keys(defaultState).forEach(key => {
        state[key] = defaultState[key]
      })
    },
    setAddSize(state, payload) {
      state.addSize = payload
    },
  },
})

export default store
