import { CombinationMode } from 'public/src/pages/checkout_new/utils/constant.js'

export default {
  /**
   * @description 布局模式
   * @returns {string} 聚合模式
   * @returns {string} 分离模式
   * */
  // layoutMode({ cartsInfo, abtInfo }) {
  //   const shoppingBagPosition =
  //     abtInfo.Shoppingbagposition?.param?.shoppingbag_position
  //   // const isAbtNew = shoppingBagPosition === 'new'
  //   const isAbtOld = shoppingBagPosition === 'old'
  //   return !cartsInfo.is_multi_mall && isAbtOld
  //     ? CombinationMode.Separation
  //     : CombinationMode.Combination
  // },
}
