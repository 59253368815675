import { cloneDeep } from 'lodash'

/**
 * @description: 设置地址相关的信息，包括默认地址、地址总数、提示等
 * @return {*}
 */
export const setShippingMethod = (state, { checkout, isInit = false }) => {
  const { quick_shipping, mall_price_list = [], } = checkout
  
  const insuranceInfo = mall_price_list?.reduce((memo, mall) => {
    return {
      ...memo,
      [mall.mall_code]: mall.insurance_info?.is_use_insurance
    }
  }, {})
  
  const selectedShippingList = checkout.shipping_methods_mall.map((item) => {
    const mall_code = +item.mall_code
    const selected = item.shipping_methods.find(item => +item.isDefault === 1)
    
    if(!selected) return null
    
    return {
      mall_code: mall_code,
      transport_type: selected.transport_type,
      last_transport_type: '',
      being_check: +selected.isDefault,
      use_insurance: insuranceInfo?.use_insurance ?? 0,
      switch_qs_flag: quick_shipping?.switch_qs?.switch_qs_selected // qs跨境运费（目前仅支持单mall站点）
    }
  }).filter(Boolean)
  
  const histories = isInit ? {
    histories: [
      cloneDeep(selectedShippingList)
    ]
  } : null
  
  Object.assign(state, {
    shippingMethodState: {
      ...state.shippingMethodState,
      ...histories,
      selectedShippingList
    }
  })
}
